import React, { useEffect, useState } from "react"
import Header from "../components/header";
import Footer from "../components/footer";
import Graphic from "../components/graphic/graphic";
import _ from "lodash";
import PortfolioCardV1 from "../components/card/portfolio-card";

import desktopSlideHr1 from '../slider/hr/desktop/1.jpg';
import desktopSlideHr2 from '../slider/hr/desktop/2.jpg';
import desktopSlideHr3 from '../slider/hr/desktop/3.jpg';
import desktopSlideHr4 from '../slider/hr/desktop/4.jpg';

import mobileSlideHr1 from '../slider/hr/mobile/1.jpg';
import mobileSlideHr2 from '../slider/hr/mobile/2.jpg';
import mobileSlideHr3 from '../slider/hr/mobile/3.jpg';
import mobileSlideHr4 from '../slider/hr/mobile/4.jpg';

import desktopSlideDe1 from '../slider/de/desktop/1.jpg';
import desktopSlideDe2 from '../slider/de/desktop/2.jpg';
import desktopSlideDe3 from '../slider/de/desktop/3.jpg';
import desktopSlideDe4 from '../slider/de/desktop/4.jpg';

import mobileSlideDe1 from '../slider/de/mobile/1.jpg';
import mobileSlideDe2 from '../slider/de/mobile/2.jpg';
import mobileSlideDe3 from '../slider/de/mobile/3.jpg';
import mobileSlideDe4 from '../slider/de/mobile/4.jpg';

import previous from '../icons/previous-black.svg';
import next from '../icons/next-black.svg';
import favicon from '../icons/favicon.ico';

import { Slide } from 'react-slideshow-image';
import { useIntl, Link } from "gatsby-plugin-intl";
import { Helmet } from "react-helmet";

const properties = {
    duration: 4000,
    transitionDuration: 1000,
    infinite: true,
    indicators: false,
    arrows: true,
    prevArrow: <button className="nav default-nav" aria-label="Previous Slide"><img alt="" src={previous} /></button>,
    nextArrow: <button className="nav default-nav" aria-label="Next Slide"><img alt="" src={next} /></button>
}

const properties2 = {
    duration: 4000,
    transitionDuration: 1000,
    infinite: true,
    indicators: false,
    arrows: true,
    prevArrow: <button className="nav default-nav" aria-label="Previous Slide"><img alt="" src={previous} /></button>,
    nextArrow: <button className="nav default-nav" aria-label="Next Slide"><img alt="" src={next} /></button>
}

export default props => {
    const intl = useIntl();
    const lang = props.pathContext.language;

    const fetchProjects = (setProjects, categories, subcategories) => {
        fetch('https://nanonet.hr/api/projects.php?lang=hr', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                page: 0,
                limit: 100,
                categories,
                subcategories,
                orderBy: "date",
                orderType: "DESC",
            })
        })
            .then(response => response.json())
            .then(resultData => {
                if (categories[0] === 'RESIDENTIAL') {
                    setProjects(_.filter(resultData, item => _.includes([26,73,57], Number(item.id))));
                }
                if (categories[0] === 'BUSINESS') {
                    setProjects(_.filter(resultData, item => _.includes([76,56,55], Number(item.id))));
                }
                if (categories[0] === 'HOSPITALITY_FACILITIES') {
                    setProjects(_.filter(resultData, item => _.includes([38,43,41], Number(item.id))));
                }
                if (categories[0] === 'SACRAL') {
                    setProjects(_.filter(resultData, item => _.includes([53,54,78], Number(item.id))));
                }
            });
    };

    const [residentialProjects, setResidentialProjects] = useState([]);
    useEffect(() => fetchProjects(setResidentialProjects, ['RESIDENTIAL'], ['STUDY', 'BATHROOM', 'LIVING_ROOM', 'CHILDRENS_ROOM', 'BEDROOM', 'KITCHEN', 'HALL']), []);
    const [businessProjects, setBusinessProjects] = useState([]);
    useEffect(() => fetchProjects(setBusinessProjects, ['BUSINESS'], ['SHOP', 'OFFICE', 'SANITATION']), []);
    const [hospitalityProjects, setHospitalityProjects] = useState([]);
    useEffect(() => fetchProjects(setHospitalityProjects, ['HOSPITALITY_FACILITIES'], ['ROOMS_APARTMANTS', 'CATERING_FACILITIES']), []);
    const [sacralProjects, setSacralProjects] = useState([]);
    useEffect(() => fetchProjects(setSacralProjects, ['SACRAL'], []), []);

    const size = useWindowSize();

    return (
        <div className="home">
            <Helmet>
                <meta property="og:image" content="https://nanonet.hr/cover.jpg"/>
                <meta property="og:image:secure_url" content="https://nanonet.hr/cover.jpg"/>
                <title>{intl.formatMessage({ id: "header.home" })}</title>
                <link rel="icon" href={favicon} />
            </Helmet>
            <Header lang={lang}/>

            <div className="carousel">
                {
                    lang === 'hr' && (size.height > size.width ?
                        <Slide {...properties}>
                            <div className="step-c"><img alt="" className="slide" src={mobileSlideHr1}/></div>
                            <div className="step-c"><img alt="" className="slide" src={mobileSlideHr2}/></div>
                            <div className="step-c"><img alt="" className="slide" src={mobileSlideHr3}/></div>
                            <div className="step-c"><img alt="" className="slide" src={mobileSlideHr4}/></div>
                        </Slide> :
                        <Slide {...properties}>
                            <div className="step-c"><img alt="" className="slide" src={desktopSlideHr1}/></div>
                            <div className="step-c"><img alt="" className="slide" src={desktopSlideHr2}/></div>
                            <div className="step-c"><img alt="" className="slide" src={desktopSlideHr3}/></div>
                            <div className="step-c"><img alt="" className="slide" src={desktopSlideHr4}/></div>
                        </Slide>
                    )
                }
                {
                    lang === 'de' && (size.height > size.width ?
                            <Slide {...properties}>
                                <div className="step-c"><img alt="" className="slide" src={mobileSlideDe1}/></div>
                                <div className="step-c"><img alt="" className="slide" src={mobileSlideDe2}/></div>
                                <div className="step-c"><img alt="" className="slide" src={mobileSlideDe3}/></div>
                                <div className="step-c"><img alt="" className="slide" src={mobileSlideDe4}/></div>
                            </Slide> :
                            <Slide {...properties}>
                                <div className="step-c"><img alt="" className="slide" src={desktopSlideDe1}/></div>
                                <div className="step-c"><img alt="" className="slide" src={desktopSlideDe2}/></div>
                                <div className="step-c"><img alt="" className="slide" src={desktopSlideDe3}/></div>
                                <div className="step-c"><img alt="" className="slide" src={desktopSlideDe4}/></div>
                            </Slide>
                    )
                }
            </div>

            <Graphic />

            <div className="projects">
                <div className="text title">
                    <Link to={"/portfolio?category=RESIDENTIAL&subcategory=KITCHEN"}>{intl.formatMessage({ id: "categories.residential" })}</Link>
                </div>
                <div className="projects-desktop">
                    {_.map(residentialProjects, project => <PortfolioCardV1 {...project}/>)}
                </div>
                <div className="projects-mobile">
                    <Slide {...properties2}>
                        {_.map(residentialProjects, project => <PortfolioCardV1 {...project}/>)}
                    </Slide>
                </div>
            </div>

            <div className="projects">
                <div className="text title">
                    <Link to={"/portfolio?category=BUSINESS&subcategory=SHOP"}>{intl.formatMessage({ id: "categories.business" })}</Link>
                </div>
                <div className="projects-desktop">
                    {_.map(businessProjects, project => <PortfolioCardV1 {...project}/>)}
                </div>
                <div className="projects-mobile">
                    <Slide {...properties2}>
                        {_.map(businessProjects, project => <PortfolioCardV1 {...project}/>)}
                    </Slide>
                </div>
            </div>

            <div className="projects">
                <div className="text title">
                    <Link to={"/portfolio?category=HOSPITALITY_FACILITIES&subcategory=ROOMS_APARTMANTS"}>{intl.formatMessage({ id: "categories.hospitality" })}</Link>
                </div>
                <div className="projects-desktop">
                    {_.map(hospitalityProjects, project => <PortfolioCardV1 {...project}/>)}
                </div>
                <div className="projects-mobile">
                    <Slide {...properties2}>
                        {_.map(hospitalityProjects, project => <PortfolioCardV1 {...project}/>)}
                    </Slide>
                </div>
            </div>

            <div className="projects">
                <div className="text title">
                    <Link to={"/portfolio?category=SACRAL"}>{intl.formatMessage({ id: "categories.sacral" })}</Link>
                </div>
                <div className="projects-desktop">
                    {_.map(sacralProjects, project => <PortfolioCardV1 {...project}/>)}
                </div>
                <div className="projects-mobile">
                    <Slide {...properties2}>
                        {_.map(sacralProjects, project => <PortfolioCardV1 {...project}/>)}
                    </Slide>
                </div>
            </div>

            <Footer/>
        </div>
    );
};

function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}
